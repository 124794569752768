export const createRandomString = () =>
  Math.random()
    .toString(36)
    .substring(4)
    .replace(/[^a-zA-Z]+/g, '')

export const convertToKebabCase = (value: string): string => {
  return value
    .replace(/[A-Z]/g, function (match) {
      return '-' + match.toLowerCase()
    })
    .replace(/^-/, '')
}
