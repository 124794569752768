import FetchProvider from './http-client/providers/FetchProvider'
import router from '../router'
import { clearState } from '@/store'
import { snakeToCamel } from '@/services/http-client/transformers/snakeToCamel'

//TODO: Add tenants for env variables
const httpProvider = new FetchProvider()

httpProvider
  .addInterceptor({
    onError: async (status) => {
      //status can be undefined if error is related to fetch API Authorization header:
      // incorrect value or cyrillic in token
      // https://stackoverflow.com/questions/49109878/typeerror-failed-to-execute-fetch-on-window-invalid-value
      if (status === 401) {
        clearState()
        await router.push({ name: 'Login' })
      }
    },
  })
  .addResponseTransformer(snakeToCamel)

export default httpProvider
