import { PERMISSIONS_SERVICE_URL } from '@/common/config'
import { HttpClient } from './http-client/HttpClient'
import httpProvider from './http-provider'
import { getAuth0Header } from './tokenManager'
import {
  IAction,
  IActionPermission,
  ICommonHeaders,
} from '@sennder/senn-node-microfrontend-interfaces'

class PermissionService extends HttpClient {
  public async getPermissions(
    actions: IAction[],
    commonHeaders: ICommonHeaders
  ): Promise<IActionPermission[]> {
    return this.post('/api/v1/me/permissions', {
      headers: {
        ...commonHeaders,
      },
      data: actions,
    })
  }
}

if (!PERMISSIONS_SERVICE_URL) {
  throw new Error('PERMISSIONS_SERVICE_URL not found')
}

export const permissionService = new PermissionService({
  httpProvider,
  getAuthHeader: getAuth0Header,
  baseURL: PERMISSIONS_SERVICE_URL,
})
