const isObject = (obj: Record<string, any>) => {
  return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function'
}

const toCamel = (key: string) => {
  return key.replace(/([-_][a-z])/gi, (str) => {
    return str.toUpperCase().replace('-', '').replace('_', '')
  })
}

export const snakeToCamel = (obj: Record<string, any>): Record<string, any> => {
  if (isObject(obj)) {
    const newObject: Record<string, any> = {}

    Object.keys(obj).forEach((k) => {
      newObject[toCamel(k)] = snakeToCamel(obj[k])
    })

    return newObject
  } else if (Array.isArray(obj)) {
    return obj.map((i) => {
      return snakeToCamel(i)
    })
  }

  return obj
}
